/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const createPrezentation = /* GraphQL */ `
  mutation CreatePrezentation(
    $input: CreatePrezentationInput!
    $condition: ModelPrezentationConditionInput
  ) {
    createPrezentation(input: $input, condition: $condition) {
      id
      name
      type
      description
      ownerID
      companyID
      teamID
      reviewersIDs
      audienceID
      beginningAssetsIDs
      middleAssetsIDs
      endAssetsIDs
      visibleTeam
      owner {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      audience {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      theme
      beginningOutlines
      beginningCategories
      beginningFilenames
      beginningUniqueIDs
      beginningThumbnails
      middleOutlines
      middleCategories
      middleFilenames
      middleThumbnails
      middleUniqueIDs
      endOutlines
      endFilenames
      endThumbnails
      endCategories
      endUniqueIDs
      ideaOutlines
      ideaCategories
      mergedSlides
      comments {
        items {
          id
          prezentationID
          userID
          username
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      countNewComments
      createdAt
      updatedAt
    }
  }
`;
export const updatePrezentation = /* GraphQL */ `
  mutation UpdatePrezentation(
    $input: UpdatePrezentationInput!
    $condition: ModelPrezentationConditionInput
  ) {
    updatePrezentation(input: $input, condition: $condition) {
      id
      name
      type
      description
      ownerID
      companyID
      teamID
      reviewersIDs
      audienceID
      beginningAssetsIDs
      middleAssetsIDs
      endAssetsIDs
      visibleTeam
      owner {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      audience {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      theme
      beginningOutlines
      beginningCategories
      beginningFilenames
      beginningUniqueIDs
      beginningThumbnails
      middleOutlines
      middleCategories
      middleFilenames
      middleThumbnails
      middleUniqueIDs
      endOutlines
      endFilenames
      endThumbnails
      endCategories
      endUniqueIDs
      ideaOutlines
      ideaCategories
      mergedSlides
      comments {
        items {
          id
          prezentationID
          userID
          username
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      countNewComments
      createdAt
      updatedAt
    }
  }
`;
export const deletePrezentation = /* GraphQL */ `
  mutation DeletePrezentation(
    $input: DeletePrezentationInput!
    $condition: ModelPrezentationConditionInput
  ) {
    deletePrezentation(input: $input, condition: $condition) {
      id
      name
      type
      description
      ownerID
      companyID
      teamID
      reviewersIDs
      audienceID
      beginningAssetsIDs
      middleAssetsIDs
      endAssetsIDs
      visibleTeam
      owner {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      audience {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      theme
      beginningOutlines
      beginningCategories
      beginningFilenames
      beginningUniqueIDs
      beginningThumbnails
      middleOutlines
      middleCategories
      middleFilenames
      middleThumbnails
      middleUniqueIDs
      endOutlines
      endFilenames
      endThumbnails
      endCategories
      endUniqueIDs
      ideaOutlines
      ideaCategories
      mergedSlides
      comments {
        items {
          id
          prezentationID
          userID
          username
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      countNewComments
      createdAt
      updatedAt
    }
  }
`;
export const createSupportRequest = /* GraphQL */ `
  mutation CreateSupportRequest(
    $input: CreateSupportRequestInput!
    $condition: ModelSupportRequestConditionInput
  ) {
    createSupportRequest(input: $input, condition: $condition) {
      id
      userID
      companyID
      useremail
      username
      comment
      user {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      fromUserID
      toUserID
      companyID
      notification
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoID
      username
      stripeID
      email
      firstName
      lastName
      role
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      defaultTheme
      sponsorID
      adminID
      privacy
      downloadLimit
      downloadUsed
      countPrezentationDownloads
      countSearches
      countSlideView
      efficiencyRating
      effectiveRating
      ratings {
        items {
          id
          userID
          assetID
          stars
          createdAt
          updatedAt
        }
        nextToken
      }
      downloads {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      overnightSlides {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      topWishes
      createdAt
      updatedAt
      tourStatus
      seenOrSkippedWelcome
      takenOrSkippedTour
      dateLastRated
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoID
      username
      stripeID
      email
      firstName
      lastName
      role
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      defaultTheme
      sponsorID
      adminID
      privacy
      downloadLimit
      downloadUsed
      countPrezentationDownloads
      countSearches
      countSlideView
      efficiencyRating
      effectiveRating
      ratings {
        items {
          id
          userID
          assetID
          stars
          createdAt
          updatedAt
        }
        nextToken
      }
      downloads {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      overnightSlides {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      topWishes
      createdAt
      updatedAt
      tourStatus
      seenOrSkippedWelcome
      takenOrSkippedTour
      dateLastRated
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoID
      username
      stripeID
      email
      firstName
      lastName
      role
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      defaultTheme
      sponsorID
      adminID
      privacy
      downloadLimit
      downloadUsed
      countPrezentationDownloads
      countSearches
      countSlideView
      efficiencyRating
      effectiveRating
      ratings {
        items {
          id
          userID
          assetID
          stars
          createdAt
          updatedAt
        }
        nextToken
      }
      downloads {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      overnightSlides {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      topWishes
      createdAt
      updatedAt
      tourStatus
      seenOrSkippedWelcome
      takenOrSkippedTour
      dateLastRated
    }
  }
`;
export const createPrivateAudience = /* GraphQL */ `
  mutation CreatePrivateAudience(
    $input: CreatePrivateAudienceInput!
    $condition: ModelPrivateAudienceConditionInput
  ) {
    createPrivateAudience(input: $input, condition: $condition) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const updatePrivateAudience = /* GraphQL */ `
  mutation UpdatePrivateAudience(
    $input: UpdatePrivateAudienceInput!
    $condition: ModelPrivateAudienceConditionInput
  ) {
    updatePrivateAudience(input: $input, condition: $condition) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const deletePrivateAudience = /* GraphQL */ `
  mutation DeletePrivateAudience(
    $input: DeletePrivateAudienceInput!
    $condition: ModelPrivateAudienceConditionInput
  ) {
    deletePrivateAudience(input: $input, condition: $condition) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const createAudience = /* GraphQL */ `
  mutation CreateAudience(
    $input: CreateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    createAudience(input: $input, condition: $condition) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const updateAudience = /* GraphQL */ `
  mutation UpdateAudience(
    $input: UpdateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    updateAudience(input: $input, condition: $condition) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudience = /* GraphQL */ `
  mutation DeleteAudience(
    $input: DeleteAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    deleteAudience(input: $input, condition: $condition) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const createCompanies = /* GraphQL */ `
  mutation CreateCompanies(
    $input: CreateCompaniesInput!
    $condition: ModelcompaniesConditionInput
  ) {
    createCompanies(input: $input, condition: $condition) {
      id
      name
      displayName
      address
      city
      State
      Country
      phone
      logo
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanies = /* GraphQL */ `
  mutation UpdateCompanies(
    $input: UpdateCompaniesInput!
    $condition: ModelcompaniesConditionInput
  ) {
    updateCompanies(input: $input, condition: $condition) {
      id
      name
      displayName
      address
      city
      State
      Country
      phone
      logo
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanies = /* GraphQL */ `
  mutation DeleteCompanies(
    $input: DeleteCompaniesInput!
    $condition: ModelcompaniesConditionInput
  ) {
    deleteCompanies(input: $input, condition: $condition) {
      id
      name
      displayName
      address
      city
      State
      Country
      phone
      logo
      createdAt
      updatedAt
    }
  }
`;
export const createTeams = /* GraphQL */ `
  mutation CreateTeams(
    $input: CreateTeamsInput!
    $condition: ModelTeamsConditionInput
  ) {
    createTeams(input: $input, condition: $condition) {
      id
      name
      companyID
      organizationID
      sponsorID
      admins
      subscriptions
      addons
      promos
      status
      description
      themes
      defaultTheme
      logo
      createdAt
      updatedAt
    }
  }
`;
export const updateTeams = /* GraphQL */ `
  mutation UpdateTeams(
    $input: UpdateTeamsInput!
    $condition: ModelTeamsConditionInput
  ) {
    updateTeams(input: $input, condition: $condition) {
      id
      name
      companyID
      organizationID
      sponsorID
      admins
      subscriptions
      addons
      promos
      status
      description
      themes
      defaultTheme
      logo
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeams = /* GraphQL */ `
  mutation DeleteTeams(
    $input: DeleteTeamsInput!
    $condition: ModelTeamsConditionInput
  ) {
    deleteTeams(input: $input, condition: $condition) {
      id
      name
      companyID
      organizationID
      sponsorID
      admins
      subscriptions
      addons
      promos
      status
      description
      themes
      defaultTheme
      logo
      createdAt
      updatedAt
    }
  }
`;
export const createSubscriptions = /* GraphQL */ `
  mutation CreateSubscriptions(
    $input: CreateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    createSubscriptions(input: $input, condition: $condition) {
      id
      planID
      StripeID
      teamID
      organizationID
      companyID
      plan {
        id
        stripeID
        name
        terms
        downloadLimit
        maxUsers
        durationDays
        price
        image
        description
        Detail
        status
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
      defaultPaymentMethod
      collectionMethod
      organization {
        id
        name
        companyID
        sponsorID
        description
        logo
        createdAt
        updatedAt
      }
      team {
        id
        name
        companyID
        organizationID
        sponsorID
        admins
        subscriptions
        addons
        promos
        status
        description
        themes
        defaultTheme
        logo
        createdAt
        updatedAt
      }
      numSeats
      paymentMethodId
      paymentMethodLast4
      paymentMethodBrand
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriptions = /* GraphQL */ `
  mutation UpdateSubscriptions(
    $input: UpdateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    updateSubscriptions(input: $input, condition: $condition) {
      id
      planID
      StripeID
      teamID
      organizationID
      companyID
      plan {
        id
        stripeID
        name
        terms
        downloadLimit
        maxUsers
        durationDays
        price
        image
        description
        Detail
        status
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
      defaultPaymentMethod
      collectionMethod
      organization {
        id
        name
        companyID
        sponsorID
        description
        logo
        createdAt
        updatedAt
      }
      team {
        id
        name
        companyID
        organizationID
        sponsorID
        admins
        subscriptions
        addons
        promos
        status
        description
        themes
        defaultTheme
        logo
        createdAt
        updatedAt
      }
      numSeats
      paymentMethodId
      paymentMethodLast4
      paymentMethodBrand
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscriptions = /* GraphQL */ `
  mutation DeleteSubscriptions(
    $input: DeleteSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    deleteSubscriptions(input: $input, condition: $condition) {
      id
      planID
      StripeID
      teamID
      organizationID
      companyID
      plan {
        id
        stripeID
        name
        terms
        downloadLimit
        maxUsers
        durationDays
        price
        image
        description
        Detail
        status
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
      defaultPaymentMethod
      collectionMethod
      organization {
        id
        name
        companyID
        sponsorID
        description
        logo
        createdAt
        updatedAt
      }
      team {
        id
        name
        companyID
        organizationID
        sponsorID
        admins
        subscriptions
        addons
        promos
        status
        description
        themes
        defaultTheme
        logo
        createdAt
        updatedAt
      }
      numSeats
      paymentMethodId
      paymentMethodLast4
      paymentMethodBrand
      active
      createdAt
      updatedAt
    }
  }
`;
export const createThemes = /* GraphQL */ `
  mutation CreateThemes(
    $input: CreateThemesInput!
    $condition: ModelThemesConditionInput
  ) {
    createThemes(input: $input, condition: $condition) {
      id
      teamID
      organizationID
      companyID
      code
      name
      description
      thumbnail
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateThemes = /* GraphQL */ `
  mutation UpdateThemes(
    $input: UpdateThemesInput!
    $condition: ModelThemesConditionInput
  ) {
    updateThemes(input: $input, condition: $condition) {
      id
      teamID
      organizationID
      companyID
      code
      name
      description
      thumbnail
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteThemes = /* GraphQL */ `
  mutation DeleteThemes(
    $input: DeleteThemesInput!
    $condition: ModelThemesConditionInput
  ) {
    deleteThemes(input: $input, condition: $condition) {
      id
      teamID
      organizationID
      companyID
      code
      name
      description
      thumbnail
      active
      createdAt
      updatedAt
    }
  }
`;
export const createUserModuleItemStatus = /* GraphQL */ `
  mutation CreateUserModuleItemStatus(
    $input: CreateUserModuleItemStatusInput!
    $condition: ModeluserModuleItemStatusConditionInput
  ) {
    createUserModuleItemStatus(input: $input, condition: $condition) {
      id
      userID
      itemNumber
      moduleNumber
      trackNumber
      levelNumber
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateUserModuleItemStatus = /* GraphQL */ `
  mutation UpdateUserModuleItemStatus(
    $input: UpdateUserModuleItemStatusInput!
    $condition: ModeluserModuleItemStatusConditionInput
  ) {
    updateUserModuleItemStatus(input: $input, condition: $condition) {
      id
      userID
      itemNumber
      moduleNumber
      trackNumber
      levelNumber
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateLearningVideos = /* GraphQL */ `
  mutation UpdateLearningVideos(
    $input: UpdateLearningVideosInput!
    $condition: ModellearningVideosConditionInput
  ) {
    updateLearningVideos(input: $input, condition: $condition) {
      id
      learningVideoNumber
      trackNumber
      levelNumber
      moduleNumber
      fileName
      name
      description
      playDuration
      author
      thumbsUp
      thumbsDown
      views
      createdAt
      updatedAt
    }
  }
`;
