/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const performAutoComplete = /* GraphQL */ `
  query PerformAutoComplete($parameters: String, $sessionkey: String) {
    performAutoComplete(parameters: $parameters, sessionkey: $sessionkey)
  }
`;
export const performSearch = /* GraphQL */ `
  query PerformSearch($parameters: String, $sessionkey: String) {
    performSearch(parameters: $parameters, sessionkey: $sessionkey)
  }
`;
export const performHybridSearch = /* GraphQL */ `
  query PerformHybridSearch($parameters: String, $sessionkey: String) {
    performHybridSearch(parameters: $parameters, sessionkey: $sessionkey)
  }
`;
export const mergeslides = /* GraphQL */ `
  query Mergeslides($parameters: String) {
    mergeslides(parameters: $parameters)
  }
`;
export const emailTrialExpiration = /* GraphQL */ `
  query EmailTrialExpiration(
    $subject: String
    $audience: String
    $requester: String
    $toAddress: String
    $message: String
    $link: String
  ) {
    emailTrialExpiration(
      subject: $subject
      audience: $audience
      requester: $requester
      toAddress: $toAddress
      message: $message
      link: $link
    )
  }
`;
export const mergeslidesSaveOnedrive = /* GraphQL */ `
  query MergeslidesSaveOnedrive($parameters: String, $accessToken: String) {
    mergeslidesSaveOnedrive(parameters: $parameters, accessToken: $accessToken)
  }
`;
export const emailShareRequest = /* GraphQL */ `
  query EmailShareRequest(
    $subject: String
    $audience: String
    $requester: String
    $toAddress: String
    $shareLink: String
    $message: String
  ) {
    emailShareRequest(
      subject: $subject
      audience: $audience
      requester: $requester
      toAddress: $toAddress
      shareLink: $shareLink
      message: $message
    )
  }
`;
export const emailSupportRequest = /* GraphQL */ `
  query EmailSupportRequest(
    $subject: String
    $audience: String
    $requester: String
    $toAddress: String
    $message: String
    $link: String
  ) {
    emailSupportRequest(
      subject: $subject
      audience: $audience
      requester: $requester
      toAddress: $toAddress
      message: $message
      link: $link
    )
  }
`;
export const emailNotification = /* GraphQL */ `
  query EmailNotification(
    $subject: String
    $audience: String
    $requester: String
    $toAddress: String
    $username: String
    $password: String
    $message: String
    $link: String
    $name: String
    $ccAddress: String
    $template: String
  ) {
    emailNotification(
      subject: $subject
      audience: $audience
      requester: $requester
      toAddress: $toAddress
      username: $username
      password: $password
      message: $message
      link: $link
      name: $name
      ccAddress: $ccAddress
      template: $template
    )
  }
`;
export const emailSignupConfirmation = /* GraphQL */ `
  query EmailSignupConfirmation(
    $subject: String
    $audience: String
    $role: String
    $requester: String
    $toAddress: String
    $username: String
    $password: String
    $message: String
    $link: String
    $name: String
  ) {
    emailSignupConfirmation(
      subject: $subject
      audience: $audience
      role: $role
      requester: $requester
      toAddress: $toAddress
      username: $username
      password: $password
      message: $message
      link: $link
      name: $name
    )
  }
`;
export const evaluateFit = /* GraphQL */ `
  query EvaluateFit($parameters: String, $sessionkey: String) {
    evaluateFIT(parameters: $parameters, sessionkey: $sessionkey)
  }
`;
export const getCfSignedUrl = /* GraphQL */ `
  query GetCfSignedUrl($url: String) {
    getCFSignedURL(url: $url)
  }
`;
export const userOperation = /* GraphQL */ `
  query UserOperation($input: UserOperationRequest!) {
    userOperation(input: $input) {
      errors
      organizationUsers {
        organizationID
        username
      }
    }
  }
`;
export const stripeSubscription = /* GraphQL */ `
  query StripeSubscription(
    $customerId: String
    $subStripeId: String
    $quantity: Int
    $planStripeId: String
    $paymentMethodId: String
    $email: String
    $customerStripeId: String
    $dbId: String
    $teamId: String
  ) {
    stripeSubscription(
      customerId: $customerId
      subStripeId: $subStripeId
      quantity: $quantity
      planStripeId: $planStripeId
      paymentMethodId: $paymentMethodId
      email: $email
      customerStripeId: $customerStripeId
      dbId: $dbId
      teamId: $teamId
    )
  }
`;
export const moveToStaging = /* GraphQL */ `
  query MoveToStaging($parameters: String) {
    moveToStaging(parameters: $parameters)
  }
`;
export const getRelatedSlides = /* GraphQL */ `
  query GetRelatedSlides($parameters: String) {
    getRelatedSlides(parameters: $parameters)
  }
`;
export const notifyUserRoleChange = /* GraphQL */ `
  query NotifyUserRoleChange($oldRole: String, $newRole: String) {
    notifyUserRoleChange(oldRole: $oldRole, newRole: $newRole)
  }
`;
export const sampleFunctionInvoke = /* GraphQL */ `
  query SampleFunctionInvoke($param1: String, $param2: String) {
    SampleFunctionInvoke(param1: $param1, param2: $param2)
  }
`;
export const getPrezentation = /* GraphQL */ `
  query GetPrezentation($id: ID!) {
    getPrezentation(id: $id) {
      id
      name
      type
      description
      ownerID
      companyID
      teamID
      reviewersIDs
      audienceID
      beginningAssetsIDs
      middleAssetsIDs
      endAssetsIDs
      visibleTeam
      owner {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      audience {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      theme
      beginningOutlines
      beginningCategories
      beginningFilenames
      beginningUniqueIDs
      beginningThumbnails
      middleOutlines
      middleCategories
      middleFilenames
      middleThumbnails
      middleUniqueIDs
      endOutlines
      endFilenames
      endThumbnails
      endCategories
      endUniqueIDs
      ideaOutlines
      ideaCategories
      mergedSlides
      comments {
        items {
          id
          prezentationID
          userID
          username
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      countNewComments
      createdAt
      updatedAt
    }
  }
`;
export const listPrezentations = /* GraphQL */ `
  query ListPrezentations(
    $filter: ModelPrezentationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrezentations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        description
        ownerID
        companyID
        teamID
        reviewersIDs
        audienceID
        beginningAssetsIDs
        middleAssetsIDs
        endAssetsIDs
        visibleTeam
        owner {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        audience {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        theme
        beginningOutlines
        beginningCategories
        beginningFilenames
        beginningUniqueIDs
        beginningThumbnails
        middleOutlines
        middleCategories
        middleFilenames
        middleThumbnails
        middleUniqueIDs
        endOutlines
        endFilenames
        endThumbnails
        endCategories
        endUniqueIDs
        ideaOutlines
        ideaCategories
        mergedSlides
        comments {
          nextToken
        }
        countNewComments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        vendorID
        ownerID
        file
        assetType
        premium
        tags
        createdAt
        updatedAt
        status
        vote {
          nextToken
        }
        pptFile
        keyword
        construct
        inkRatio
        image
        imageSize
        icon
        color
        cartoon
        threeD
        shading
        shapes
        graphs
        tables
        downloadCount
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoID
      username
      stripeID
      email
      firstName
      lastName
      role
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      defaultTheme
      sponsorID
      adminID
      privacy
      downloadLimit
      downloadUsed
      countPrezentationDownloads
      countSearches
      countSlideView
      efficiencyRating
      effectiveRating
      ratings {
        items {
          id
          userID
          assetID
          stars
          createdAt
          updatedAt
        }
        nextToken
      }
      downloads {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      views {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      overnightSlides {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      favorites {
        items {
          id
          userID
          assetID
          createdAt
          updatedAt
        }
        nextToken
      }
      topWishes
      createdAt
      updatedAt
      tourStatus
      seenOrSkippedWelcome
      takenOrSkippedTour
      dateLastRated
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      nextToken
    }
  }
`;
export const getPrivateAudience = /* GraphQL */ `
  query GetPrivateAudience($id: ID!) {
    getPrivateAudience(id: $id) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const listPrivateAudiences = /* GraphQL */ `
  query ListPrivateAudiences(
    $filter: ModelPrivateAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateAudiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        email
        firstName
        lastName
        username
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        userID
        sponsorID
        adminID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAudience = /* GraphQL */ `
  query GetAudience($id: ID!) {
    getAudience(id: $id) {
      id
      cognitoID
      email
      firstName
      lastName
      username
      jobTitle
      jobFunction
      company
      manager
      managerEmail
      avatar
      profileImage
      fingerPrint
      bio
      visualPreference
      storyPreference
      dataPreference
      personalPreference
      active
      tags
      companyID
      companyName
      organizationID
      organizationName
      teamID
      teamName
      userID
      sponsorID
      adminID
      createdAt
      updatedAt
    }
  }
`;
export const listAudiences = /* GraphQL */ `
  query ListAudiences(
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        email
        firstName
        lastName
        username
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        userID
        sponsorID
        adminID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanies = /* GraphQL */ `
  query GetCompanies($id: ID!) {
    getCompanies(id: $id) {
      id
      name
      displayName
      address
      city
      State
      Country
      phone
      logo
      createdAt
      updatedAt
    }
  }
`;
export const listCompaniess = /* GraphQL */ `
  query ListCompaniess(
    $filter: ModelcompaniesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        displayName
        address
        city
        State
        Country
        phone
        logo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeams = /* GraphQL */ `
  query GetTeams($id: ID!) {
    getTeams(id: $id) {
      id
      name
      companyID
      organizationID
      sponsorID
      admins
      subscriptions
      addons
      promos
      status
      description
      themes
      defaultTheme
      logo
      createdAt
      updatedAt
    }
  }
`;
export const listTeamss = /* GraphQL */ `
  query ListTeamss(
    $filter: ModelTeamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        companyID
        organizationID
        sponsorID
        admins
        subscriptions
        addons
        promos
        status
        description
        themes
        defaultTheme
        logo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubscriptions = /* GraphQL */ `
  query GetSubscriptions($id: ID!) {
    getSubscriptions(id: $id) {
      id
      planID
      StripeID
      teamID
      organizationID
      companyID
      plan {
        id
        stripeID
        name
        terms
        downloadLimit
        maxUsers
        durationDays
        price
        image
        description
        Detail
        status
        createdAt
        updatedAt
      }
      startDate
      endDate
      status
      defaultPaymentMethod
      collectionMethod
      organization {
        id
        name
        companyID
        sponsorID
        description
        logo
        createdAt
        updatedAt
      }
      team {
        id
        name
        companyID
        organizationID
        sponsorID
        admins
        subscriptions
        addons
        promos
        status
        description
        themes
        defaultTheme
        logo
        createdAt
        updatedAt
      }
      numSeats
      paymentMethodId
      paymentMethodLast4
      paymentMethodBrand
      active
      createdAt
      updatedAt
    }
  }
`;
export const listSubscriptionss = /* GraphQL */ `
  query ListSubscriptionss(
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        planID
        StripeID
        teamID
        organizationID
        companyID
        plan {
          id
          stripeID
          name
          terms
          downloadLimit
          maxUsers
          durationDays
          price
          image
          description
          Detail
          status
          createdAt
          updatedAt
        }
        startDate
        endDate
        status
        defaultPaymentMethod
        collectionMethod
        organization {
          id
          name
          companyID
          sponsorID
          description
          logo
          createdAt
          updatedAt
        }
        team {
          id
          name
          companyID
          organizationID
          sponsorID
          admins
          subscriptions
          addons
          promos
          status
          description
          themes
          defaultTheme
          logo
          createdAt
          updatedAt
        }
        numSeats
        paymentMethodId
        paymentMethodLast4
        paymentMethodBrand
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThemes = /* GraphQL */ `
  query GetThemes($id: ID!) {
    getThemes(id: $id) {
      id
      teamID
      organizationID
      companyID
      code
      name
      description
      thumbnail
      active
      createdAt
      updatedAt
    }
  }
`;
export const listThemess = /* GraphQL */ `
  query ListThemess(
    $filter: ModelThemesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThemess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        organizationID
        companyID
        code
        name
        description
        thumbnail
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLearningLevels = /* GraphQL */ `
  query ListLearningLevels(
    $filter: ModellearningLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        levelNumber
        trackNumber
        title
        subTitle
        altTitle
        modulesCount
        modules {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLearningModules = /* GraphQL */ `
  query ListLearningModules(
    $filter: ModellearningModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        moduleNumber
        trackNumber
        levelNumber
        title
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listModuleItems = /* GraphQL */ `
  query ListModuleItems(
    $filter: ModelmoduleItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemNumber
        trackNumber
        moduleNumber
        levelNumber
        title
        subTitle
        type
        typeItemNumber
        icon
        buttonLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuizzess = /* GraphQL */ `
  query ListQuizzess(
    $filter: ModelQuizzesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quizNumber
        trackNumber
        moduleNumber
        levelNumber
        numberOfQuestions
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuizQuestions = /* GraphQL */ `
  query ListQuizQuestions(
    $filter: ModelQuizQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quizID
        questionNumber
        description
        numberOfQuestions
        answerIndex
        Questions
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLearningTracks = /* GraphQL */ `
  query ListLearningTracks(
    $filter: ModellearningTrackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningTracks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trackNumber
        name
        numberOfLevels
        numberOfModulesPerLevel
        numberOfBadgesPerLevel
        levels {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLearningVideoss = /* GraphQL */ `
  query ListLearningVideoss(
    $filter: ModellearningVideosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLearningVideoss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        learningVideoNumber
        trackNumber
        levelNumber
        moduleNumber
        fileName
        name
        description
        playDuration
        author
        thumbsUp
        thumbsDown
        views
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const prezentationByOwner = /* GraphQL */ `
  query PrezentationByOwner(
    $ownerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPrezentationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prezentationByOwner(
      ownerID: $ownerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        description
        ownerID
        companyID
        teamID
        reviewersIDs
        audienceID
        beginningAssetsIDs
        middleAssetsIDs
        endAssetsIDs
        visibleTeam
        owner {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        audience {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        theme
        beginningOutlines
        beginningCategories
        beginningFilenames
        beginningUniqueIDs
        beginningThumbnails
        middleOutlines
        middleCategories
        middleFilenames
        middleThumbnails
        middleUniqueIDs
        endOutlines
        endFilenames
        endThumbnails
        endCategories
        endUniqueIDs
        ideaOutlines
        ideaCategories
        mergedSlides
        comments {
          nextToken
        }
        countNewComments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const prezentationByCompany = /* GraphQL */ `
  query PrezentationByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPrezentationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prezentationByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        description
        ownerID
        companyID
        teamID
        reviewersIDs
        audienceID
        beginningAssetsIDs
        middleAssetsIDs
        endAssetsIDs
        visibleTeam
        owner {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        audience {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        theme
        beginningOutlines
        beginningCategories
        beginningFilenames
        beginningUniqueIDs
        beginningThumbnails
        middleOutlines
        middleCategories
        middleFilenames
        middleThumbnails
        middleUniqueIDs
        endOutlines
        endFilenames
        endThumbnails
        endCategories
        endUniqueIDs
        ideaOutlines
        ideaCategories
        mergedSlides
        comments {
          nextToken
        }
        countNewComments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByCompany = /* GraphQL */ `
  query UserByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        username
        stripeID
        email
        firstName
        lastName
        role
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        defaultTheme
        sponsorID
        adminID
        privacy
        downloadLimit
        downloadUsed
        countPrezentationDownloads
        countSearches
        countSlideView
        efficiencyRating
        effectiveRating
        ratings {
          nextToken
        }
        downloads {
          nextToken
        }
        views {
          nextToken
        }
        overnightSlides {
          nextToken
        }
        favorites {
          nextToken
        }
        topWishes
        createdAt
        updatedAt
        tourStatus
        seenOrSkippedWelcome
        takenOrSkippedTour
        dateLastRated
      }
      nextToken
    }
  }
`;
export const priavteAudienceByUser = /* GraphQL */ `
  query PriavteAudienceByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPrivateAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    priavteAudienceByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        email
        firstName
        lastName
        username
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        userID
        sponsorID
        adminID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const audienceByCompany = /* GraphQL */ `
  query AudienceByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audienceByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        email
        firstName
        lastName
        username
        jobTitle
        jobFunction
        company
        manager
        managerEmail
        avatar
        profileImage
        fingerPrint
        bio
        visualPreference
        storyPreference
        dataPreference
        personalPreference
        active
        tags
        companyID
        companyName
        organizationID
        organizationName
        teamID
        teamName
        userID
        sponsorID
        adminID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamsByCompany = /* GraphQL */ `
  query TeamsByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        companyID
        organizationID
        sponsorID
        admins
        subscriptions
        addons
        promos
        status
        description
        themes
        defaultTheme
        logo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subscriptionsByCompany = /* GraphQL */ `
  query SubscriptionsByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionsByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planID
        StripeID
        teamID
        organizationID
        companyID
        plan {
          id
          stripeID
          name
          terms
          downloadLimit
          maxUsers
          durationDays
          price
          image
          description
          Detail
          status
          createdAt
          updatedAt
        }
        startDate
        endDate
        status
        defaultPaymentMethod
        collectionMethod
        organization {
          id
          name
          companyID
          sponsorID
          description
          logo
          createdAt
          updatedAt
        }
        team {
          id
          name
          companyID
          organizationID
          sponsorID
          admins
          subscriptions
          addons
          promos
          status
          description
          themes
          defaultTheme
          logo
          createdAt
          updatedAt
        }
        numSeats
        paymentMethodId
        paymentMethodLast4
        paymentMethodBrand
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subscriptionsByTeam = /* GraphQL */ `
  query SubscriptionsByTeam(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionsByTeam(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planID
        StripeID
        teamID
        organizationID
        companyID
        plan {
          id
          stripeID
          name
          terms
          downloadLimit
          maxUsers
          durationDays
          price
          image
          description
          Detail
          status
          createdAt
          updatedAt
        }
        startDate
        endDate
        status
        defaultPaymentMethod
        collectionMethod
        organization {
          id
          name
          companyID
          sponsorID
          description
          logo
          createdAt
          updatedAt
        }
        team {
          id
          name
          companyID
          organizationID
          sponsorID
          admins
          subscriptions
          addons
          promos
          status
          description
          themes
          defaultTheme
          logo
          createdAt
          updatedAt
        }
        numSeats
        paymentMethodId
        paymentMethodLast4
        paymentMethodBrand
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const themesByCompany = /* GraphQL */ `
  query ThemesByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelThemesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    themesByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        organizationID
        companyID
        code
        name
        description
        thumbnail
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const themesByTeam = /* GraphQL */ `
  query ThemesByTeam(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelThemesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    themesByTeam(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        organizationID
        companyID
        code
        name
        description
        thumbnail
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const favoriteByUser = /* GraphQL */ `
  query FavoriteByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelfavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favoriteByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        assetID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const downloadByUser = /* GraphQL */ `
  query DownloadByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDownloadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    downloadByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        assetID
        replacedImage
        asset {
          id
          name
          description
          vendorID
          ownerID
          file
          assetType
          premium
          tags
          createdAt
          updatedAt
          status
          pptFile
          keyword
          construct
          inkRatio
          image
          imageSize
          icon
          color
          cartoon
          threeD
          shading
          shapes
          graphs
          tables
          downloadCount
        }
        user {
          id
          cognitoID
          username
          stripeID
          email
          firstName
          lastName
          role
          jobTitle
          jobFunction
          company
          manager
          managerEmail
          avatar
          profileImage
          fingerPrint
          bio
          visualPreference
          storyPreference
          dataPreference
          personalPreference
          active
          tags
          companyID
          companyName
          organizationID
          organizationName
          teamID
          teamName
          defaultTheme
          sponsorID
          adminID
          privacy
          downloadLimit
          downloadUsed
          countPrezentationDownloads
          countSearches
          countSlideView
          efficiencyRating
          effectiveRating
          topWishes
          createdAt
          updatedAt
          tourStatus
          seenOrSkippedWelcome
          takenOrSkippedTour
          dateLastRated
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const itemStatusByUser = /* GraphQL */ `
  query ItemStatusByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModeluserModuleItemStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemStatusByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        itemNumber
        moduleNumber
        trackNumber
        levelNumber
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const moduleStatusByUser = /* GraphQL */ `
  query ModuleStatusByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModeluserModuleStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moduleStatusByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        moduleNumber
        trackNumber
        levelNumber
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userLearnIndexByCompany = /* GraphQL */ `
  query UserLearnIndexByCompany(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModeluserLearnIndexFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userLearnIndexByCompany(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        organizationID
        teamID
        userID
        track
        level
        module
        moduleIndex
        completedModuleIndex
        completedLevels
        completedModules
        completedTracks
        badgesEarned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
